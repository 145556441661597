<template>
  <div class="orderDetails">
    <nav-bar left-text="订单详情" left-arrow @click-left="upperStory" class="navigation"/>
    <!-- 交易状态 -->
    <div class="state">
      <div
        class="noPay"
        v-for="(item, index) in payState"
        :key="index"
        v-show="(index + 1) == list.text_status?true:false"
      >{{item}}</div>
    </div>

    <!-- 地址 -->
    <div class="address">
      <img src="@static/home/address.png" alt />
      <div class="add_detail" v-if="Object.keys(list).length != 0">
        <div class="top">
          <span>{{list.address.name}}</span>
          <span>{{list.address.mobile}}</span>
        </div>
        <div class="btm">
          <span>{{list.address.area}} {{list.address.address}}</span>
        </div>
      </div>
    </div>
    <div class="margin10"></div>

    <!-- 购买商品信息 -->
    <div class="orderInfor">
      <div class="shopName">
        <span v-if="!list.store">平台自营</span>
        <span v-else>{{list.store.title}}</span>
      </div>
      <div class="particulars">
        <div class="img_detail_price" v-for="(item, index) in list.goods" :key="index">
          <img :src="item.image_url" alt />
          <div class="detail">
            <p>{{item.name}}</p>
            <div style="margin-top:8px;">
              <span class="seven_day">七天无理由退换</span>
            </div>
          </div>
          <div class="rgt_price">
            <p>￥{{item.price}}</p>
            <p>
              <span>×{{item.nums}}</span>
            </p>
            <div class="refund" v-if="list.text_status==2||list.text_status==3">
              <Button type="default" size="mini">退款</Button>
            </div>
          </div>
        </div>
        <div class="name_price">
          <span>商品总价</span>
          <span>￥{{list.goods_amount}}</span>
        </div>
        <div class="name_price">
          <span>运费</span>
          <span>￥00.00</span>
        </div>
        <div class="name_price color_size">
          <span>订单总价</span>
          <span>￥{{list.order_amount}}</span>
        </div>
      </div>
    </div>

    <Panel class="order-con" title="订单备注" :desc="list.message"></Panel>

    <Panel title="订单信息" class="order-con">
      <div class="information">
        <div class="content">
          <span class="lft">订单编号</span>
          <span>{{list.ordersn}}</span>
        </div>
        <div class="content">
          <span class="lft">创建时间</span>
          <span>{{list.create_time}}</span>
        </div>
        <div class="content" v-if="list.payment_time">
          <span class="lft">支付时间：</span>
          <span>{{this.Paytime}}</span>
        </div>
      </div>
    </Panel>

    <Panel title="商品金额" class="order-con">
      <div class="information">
        <div class="content">
          <span class="lft">商品总额</span>
          <span>{{list.amount}}</span>
        </div>
        <div class="content">
          <span class="lft">店铺优惠券</span>
          <span>-5</span>
        </div>
        <div class="content">
          <span class="lft">积分抵用</span>
          <span>0</span>
        </div>
        <div class="content">
          <span class="lft">平台优惠券</span>
          <span>0</span>
        </div>
      </div>
    </Panel>


    <!-- 订单操作 -->
    <div class="operation" v-if="list.text_status == 1">
      <Button size="small" round class="bntn" @click="showModel = true">取消订单</Button>
      <Button size="small" round color="#ff8100" plain class="bntn" @click="pay = true">付款</Button>
    </div>
    <div class="operation" v-if="list.text_status == 3">
      <Button size="small" round color="#ff8100" plain class="bntn">确认收货</Button>
    </div>
    <div class="operation" v-if="list.text_status == 4">
      <Button size="small" round color="#ff8100" plain class="bntn" @click="awaitEvaluate">待评价</Button>
    </div>

    <!-- 取消订单 -->
    <Popup v-model="showModel" closeable position="bottom" :style="{ height: '68%' }">
      <div class="pay-type">取消订单</div>
      <div class="remind">
        <span>温馨提醒：</span>订单取消后将无法恢复，特价、优惠等资格一并取消，
        优惠券、积分、余额等将返还到您的账户中，请及时使用
      </div>
      <RadioGroup v-model="radio">
        <CellGroup>
          <Cell
            :title="item"
            clickable
            @click="radio = idx"
            v-for="(item, idx) in personList"
            :key="idx"
          >
            <Radio slot="right-icon" :name="idx"></Radio>
          </Cell>
        </CellGroup>
      </RadioGroup>
      <Button
        size="large"
        class="make-person"
        @click="outOrder"
        color="linear-gradient(to right, #FF8000, #FFB101)"
      >确定</Button>
    </Popup>

    <!--支付方式-->
		<Popup v-model="pay" closeable position="bottom" :style="{ height: '40%' }" :close-on-click-overlay="false">
			<div class="pay-type"> 支付方式 </div>
			<div class="pay-type-item">
				<div :class="payIdx == idx ? 'active':''" v-for="(item, idx) in paytype" :key="idx" @click="setPay(idx)">{{item}}</div>
			</div>
			<span v-if="payIdx == 3 ? false:true" style="margin-left: 10px">支持支付宝、微信、余额、VIP乐通卡等在线支付</span>
			<span v-if="payIdx == 3" style="margin-left: 10px">支持用户线下通过网银转账方式进行支付</span>
			<div class="make-btn" @click="payMon">
				<span>确定</span>
			</div>
		</Popup>
    <!--支付-->
		<PayMoney v-if="payModel" ref="pay" :paytype="payIdx" :ordersn="ordersn" :money="String(list.goods_amount)"></PayMoney>
  </div>
</template>

<script>
import { getWxConfig } from "@/utils/wxpay";
import PayMoney from "@components/public/PayMoney";
import Time from "../../lin/filter/time";
import Order from "@api/order";
import { NavBar, Panel, Button, Popup, RadioGroup, Radio, Cell } from "vant";
export default {
  name: "orderDetails",
  components: {
    NavBar,
    Button,
    Popup,
    RadioGroup,
    Radio,
    Cell,
    Panel,
    PayMoney
  },
  data() {
    return {
      // 1=待付款 2=待发货 3=待收货 4=待评价 5=已评价 6=已完成 7=已取消
      payState: [
        "等待卖家付款",
        "待发货",
        "待收货",
        "待评价",
        "已评价",
        "已完成",
        "已取消"
      ],
      ordersn: this.$route.query.ordersn,
      paytype: ['支付宝', '微信', '余额'],
      pay: false,
      list: {},
      payIdx: 0,
      Paytime: "",
      payModel: true,
      showModel: false,
      radio: "1",
      personList: [
        "我不想买了",
        "信息填写错误(地址、联系人、支付方式等)",
        "重复下单/误下单",
        "对价格不满，等降价了再买",
        "商家缺货，无法支付",
        "未参加优惠，重新购买",
        "其他原因"
      ]
    };
  },
  mounted() {
    let jsonData = {
      id: 42,
      status: "OK",
      data: [867, 5309]
    };

    let { id, status, data: number } = jsonData;

    console.log(id, status, number);
    console.log(this.getInfor.name);
    // this.$nextTick(() => {
    this.getInfor();
    // })
  },
  methods: {
    awaitEvaluate() {
      this.$router.push({
        path: "/evaluate",
        query: {
          ordersn: this.ordersn
        }
      })
    },
    setPay(idx) {
			this.payIdx = idx;
		},
    // 支付
    payMon() {
      if (this.payIdx == 0) {
        console.log('支付宝');
      } else if (this.payIdx == 1) {
        getWxConfig(this.ordersn);
      } else if (this.payIdx == 2) {
        this.pay = false;
        this.$refs.pay.showPayToast();
      }
    },
    showPayToast() {
			this.pay = false;
			if (this.payIdx == 2) {
				this.$nextTick(() => {
					this.$refs.pay.showPayToast();
				}, 100)
			}
		},
    // 取消订单
    async outOrder() {
      const res = await Order.outOderPay(
        this.ordersn,
        this.personList[this.radio],
        ""
      );
      if (res.code == 200) {
        this.$toast("取消订单...");
        this.$router.go(-1);
        this.showModel = false;
      } else {
        throw res.msg;
      }
    },
    upperStory() {
      this.$router.go(-1);
    },
    async getInfor() {
      try {
        const res = await Order.getOrderDetail(this.ordersn);
        this.list = res.data;
        this.Paytime = Time.setTime(this.list.payment_time);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.orderDetails {
  width: 100%;
  height: 100vh;
  padding: 46px 0;
  overflow-y: scroll;
  background-color: #f3f3f3;
  .state {
    width: 100%;
    height: 110px;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    background:linear-gradient(-90deg,rgba(255,80,0,1) 0%,rgba(255,144,0,1) 100%);
    .noPay {
      color: #ffffff;
      font-size: 14px;
    }
  }
  .address {
    width: 96%;
    margin: 0 auto;
    padding: 10px;
    padding-right: 15px;
    display: flex;
    margin-top: -40px;
    border-radius: 3px;
    background: #fff;
    flex-direction: row;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
    .add_detail {
      margin-left: 10px;
      .btm {
        margin-top: 5px;
      }
    }
  }
  .order-con {
    width: 96%;
    border-radius: 3px;
    margin: 10px auto;
  }
  .orderInfor {
    width: 96%;
    margin: 0 auto;
    background: #fff;
    margin-top: 10px;
    .shopName {
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      font-size: 12px;
    }
    .particulars {
      padding: 0 16px 12px;
      .img_detail_price {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 10px;
        }
        .detail {
          width: 225px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .seven_day {
            color: #ff3300;
            background: #ff480054;
            border-radius: 3px;
          }
        }
        .rgt_price {
          text-align: right;
          .refund {
            margin-top: 15px;
          }
        }
      }
      .name_price {
        margin-top: 5px;
        color: #a0a0a0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .color_size {
        font-size: 14px;
        color: #000;
      }
    }
  }
  .information {
    padding: 0 16px;
    .content {
      line-height: 16px;
      padding: 5px;
      display: flex;
      flex-direction: row;
      .lft {
        width: 80px;
      }
    }
  }
  .operation {
    width: 100%;
    background: #fff;
    height: 40px;
    border-top: 1px solid #f3f3f3;
    position: fixed;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    button {
      margin-left: 10px;
    }
  }
  .pay-type {
		width: 100%;
		height: 45px;
		font-size: 16px;
		color: #333333;
		line-height: 45px;
		text-align: center;
		border-bottom: 1px solid #dfdfdf;
	}
	.pay-type-item {
		width: 100%;
		height: 60px;
		display: flex;
		margin-top: 10px;
		div {
			width: 72px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			border-radius: 20px;
			margin-left: 10px;
			color: #8C8C8C;
			border: 1px solid #8C8C8C;
		}
		.active {
			color: #FF8000;
			border: 1px solid #FF8000;
		}
	}
  .make-btn {
		position: absolute;
		bottom: 0;
		width: 96%;
		height: 35px;
		line-height: 35px;
		color: #ffffff;
		text-align: center;
		background: linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
		left: 50%;
		font-size: 16px;
		border-radius: 6px;
		margin-left: -48%;
		margin-bottom: 10px;
	}
  .pay-type {
    width: 100%;
    height: 45px;
    font-size: 16px;
    color: #333333;
    line-height: 45px;
    text-align: center;
    border-bottom: 1px solid #dfdfdf;
  }
  .remind {
    width: 100%;
    line-height: 20px;
    padding: 4px 10px;
    font-size: 12px;
  }
  .make-person {
    width: 98%;
    left: 50%;
    height: 50px;
    line-height: 50px;
    margin-left: -49%;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
.orderDetails {
  .navigation {
    .van-nav-bar__text,
    .van-icon {
      color: #ffffff !important;
    }
  }
  .van-nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    background:linear-gradient(-90deg,rgba(255,80,0,1) 0%,rgba(255,144,0,1) 100%);
  }
  .van-hairline--bottom::after {
      border: 0;
    }
}
</style>